import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-component-one-alpha',
  templateUrl: './component-one-alpha.component.html',
  styleUrls: ['./component-one-alpha.component.css']
})
export class ComponentOneAlphaComponent implements OnInit {

  constructor() { }

  $message = 'componentOne-alpha';

  ngOnInit() {
  }

}
