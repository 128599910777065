import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-main-tab-nav-bar',
  templateUrl: './main-tab-nav-bar.component.html',
  styleUrls: ['./main-tab-nav-bar.component.css']
})
export class MainTabNavBarComponent implements OnInit {
  navLinks: any[];
  activeLinkIndex = -1;
  constructor(private router: Router) {
    this.navLinks = [
      {
        label: 'Profile',
        link: './profile',
        index: 0
      }, {
        label: 'Demos',
        link: './demos',
        index: 1
      }
    ];
  }

  ngOnInit() {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
  }

}
