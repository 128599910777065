import { Component, OnInit } from '@angular/core';
declare const vanillaDemo: any;

@Component({
  selector: 'app-vanilla-demo-cars-data',
  templateUrl: './vanilla-demo-cars-data.component.html',
  styleUrls: ['./vanilla-demo-cars-data.component.css']
})
export class VanillaDemoCarsDataComponent implements OnInit {

  constructor() {
  }
  ngOnInit() {
  }
  loadVanillaDemo() {
    vanillaDemo();
  }
}
